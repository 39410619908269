import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, PreloadAllModules, NoPreloading } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { CoreModules } from 'app/modules-core/core.module';
import { environment } from 'environments/environment';
import localeEnIn from '@angular/common/locales/en-IN';
import { registerLocaleData } from '@angular/common';
import { VersionCheckService } from './version-check.service';
registerLocaleData(localeEnIn);

const appRoutes: Routes = [
  {
    path: '**',
    redirectTo: 'login'
  },
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: (environment.preloadingStrategy === 'NoPreloading')
        ? NoPreloading
        : PreloadAllModules
    }),

    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    LayoutModule,

    CoreModules,
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    VersionCheckService,
    { provide: LOCALE_ID, useValue: 'en_IN' }
  ]
})
export class AppModule {
}
