import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpEvent, HttpEventType, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "../authentication/auth.service";
import * as _ from 'lodash';
import { LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_REDIRECT, LOCAL_STORAGE_USER } from "app/storage.constants";
import { SnackbarService } from "app/modules-core/utility/snackbar.service";

@Injectable()
export class AccessTokenExpiration implements HttpInterceptor {
  tokenService: any;
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private snackBar: SnackbarService,
  ) { }

  // intercept(req: HttpRequest<any>, next: HttpHandler):
  intercept(req, next: HttpHandler):
    Observable<HttpEvent<any>> {

    /**
     * TODO - remove the tap and break the next.handle chain when error is 401
     * so in this case other interceptors will by bypassed
     * also cancel all other pending API calls
     * also move this interceptor to top in module providers
     */
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => { }, (error: any) => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          //this._authService.logout();

          if (!window.localStorage[LOCAL_STORAGE_REDIRECT]) {
            window.localStorage[LOCAL_STORAGE_REDIRECT] = window.location.pathname;
          }

          window.localStorage.removeItem(LOCAL_STORAGE_USER);
          window.localStorage.removeItem(LOCAL_STORAGE_TOKEN);
          this._router.navigate(['/login']);
          setTimeout(() => { //tmp
            this.snackBar.open('Your session expired, please login again', 'Close');
          }, 500);
        }
      }
    }));
  }

}