import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SidebarFilterContainerService {
  private _currentFilterComponent = new Subject<any>();
  currentFilterComponent$ = this._currentFilterComponent.asObservable();

  private filterParams = new Subject<any>();
  private filterParams$ = this.filterParams.asObservable();

  reset: BehaviorSubject<boolean> = new BehaviorSubject(false);
  reset$: Observable<boolean> = this.reset.asObservable();

  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private _fuseConfigService: FuseConfigService,
  ) { }

  get filters$() {
    return this.filterParams$;
  }

  get filterEmitter() {
    return this.filterParams;
  }

  setCurrentFilter(component) {
    this._currentFilterComponent.next(component);

    //this will show the icon to open the filter
    this._fuseConfigService.setConfig({
      hasSidebarFilter: true
    });

    //TODO - check when we can unsubscribe or switchMap
    this.filterParams$.subscribe(opts => {

      //this will animate the icon to open the filter
      //as visual indication that filters are active
      this._fuseConfigService.setConfig({
        sidebarFiltersActive: !_.isEmpty(opts)
      });
    })

  }

  unsetfilter() {
    this._fuseConfigService.setConfig({
      hasSidebarFilter: false
    });
  }

  getCurrentFilter$() {
    return this.currentFilterComponent$;
  }

  toggleSidebarOpen(): void {
    this._fuseSidebarService.getSidebar('themeOptionsPanel').toggleOpen();
  }
}