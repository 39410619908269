/**
 * Add this file in
 * Location: www/prod/app.constants.ts
 */
export const DEBUG_INFO_ENABLED: boolean = true;

//TODO - this has already been moved to api.constants.ts
//after all services get rid of usage of SERVER_API_URL, delete the following from this file
export const APP_URL = `//${window.location.hostname}${getFrontendPort(window.location.port)}/`;
export const SERVER_API_URL = `//${window.location.hostname}:${getBackendPort(window.location.port)}/api/`;
export const RESOURCE_URL_BASE = `//${window.location.hostname}/resources/`;
function getBackendPort(port = '') {
  const portLocal = '';

  const portMap = {
    80: '',
    8003: 9003,
    8002: 9397,
    '': '',
  };

  //TODO - check why this is returning undefined and not portLocal in local env on forhads machine
  // return portMap[port] || portLocal;
  return portMap[port] ? portMap[port] : portLocal;
}

function getFrontendPort(port) {
  const portLocal = port ? `:${port}` : '';
  return portLocal;
}
