<div id="forgot-password"
  fxLayout="column">

  <div id="forgot-password-form-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center">

    <div id="forgot-password-form"
      [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

      <div class="logo">
        <img src="assets/images/logos/fuse.svg">
      </div>

      <div class="title">RESET YOUR PASSWORD</div>

      <form>
        <table>
          <tr>
            <td>Password:</td>
            <td>
              <mat-form-field class="w-100-p">
                <input matInput
                  type="password"
                  name="password"
                  required
                  [(ngModel)]="data.password"
                  pattern="(?=.*\d).{6,}"
                  #password="ngModel" />
                <mat-error
                  *ngIf="(password.invalid && (password.dirty || password.touched)) && password.errors.required">
                  Password is required!
                </mat-error>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td> Confirm Password:</td>
            <td>
              <mat-form-field class="w-100-p">
                <input matInput
                  type="password"
                  name="confirmpassword"
                  required
                  [(ngModel)]="data.confirmpassword"
                  #confirmpassword="ngModel" />
                <mat-error
                  *ngIf="(confirmpassword.invalid && (confirmpassword.dirty || confirmpassword.touched)) && confirmpassword.errors.required">
                  Password is required!
                </mat-error>
                <span style="color: red"
                  *ngIf="data.confirmpassword!==data.password"> Passwords
                  must be same!</span>
              </mat-form-field>
            </td>
          </tr>
        </table>
        <btn style="float: right; margin-top: 10px;"
          [text]="'save'"
          (click)="resetPassword()"></btn>
      </form>



      <!-- <div class="login"
        fxLayout="row"
        fxLayoutAlign="center center">
        <a class="link"
          [routerLink]="'/login'">Go back to login</a>
      </div> -->

    </div>

  </div>

</div>