import { NgModule } from '@angular/core';
import { LoginModule } from 'app/authentication/login/login.module';
import { ForgotPasswordModule } from 'app/authentication/forgot-password/forgot-password.module';
import { AccessTokenExpiration } from 'app/authentication/access-token-expiration.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './access-token.interceptor';

@NgModule({
  imports: [
    LoginModule,
    ForgotPasswordModule,
  ],
  providers: [
    AccessTokenExpiration,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenExpiration,
      multi: true,
    },
  ],
})
export class AuthModule {

}
