import { NgModule } from '@angular/core';
import { BtnComponent } from './components/btn.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { BtnBaseComponent } from './components/btn-base.component';
import { BtnIconComponent } from './components/btn-icon.component';
import { BtnSmallComponent } from './components/btn-small.component';

@NgModule({
  declarations: [
    BtnBaseComponent,
    BtnComponent,
    BtnIconComponent,
    BtnSmallComponent,
  ],
  entryComponents: [
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FuseSharedModule,
  ],
  exports: [
    BtnComponent,
    BtnIconComponent,
    BtnSmallComponent,
  ]
})

export class ButtonModule {
}
