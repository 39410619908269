import { Router } from '@angular/router';
import * as _ from 'lodash';
import { UserService } from 'app/modules-core/common/user/user.service';
import { LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_USER } from 'app/storage.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../modules-core/common/user/user.service";
var AuthService = /** @class */ (function () {
    function AuthService(router, _userService) {
        this.router = router;
        this._userService = _userService;
        this.currentUserString = '';
        this.currentUser = {};
        this.currentUserPermissions = {};
        this.getCurrentUser();
    }
    AuthService.prototype.getCurrentUser = function () {
        if (this.currentUserString = window.localStorage[LOCAL_STORAGE_USER]) {
            this.currentUser = JSON.parse(this.currentUserString);
            this.currentUserPermissions = _.get(this.currentUser, 'roles.0.permissions');
        }
        return this.currentUser;
    };
    //todo
    AuthService.prototype.login = function () {
    };
    AuthService.prototype.hasPermission = function (permissionName) {
        if (this.currentUserPermissions) {
            return !!_.find(this.currentUserPermissions, function (perms) { return _.get(perms, 'name') === permissionName; });
        }
        return false;
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        this._userService.logout().subscribe(function (data) {
            console.log('Logout Backend!');
        }).add(function () {
            console.log('Logout!');
            window.localStorage.removeItem(LOCAL_STORAGE_USER);
            window.localStorage.removeItem(LOCAL_STORAGE_TOKEN);
            _this.router.navigate(['login']);
        });
        ;
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.Router), i0.inject(i2.UserService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
