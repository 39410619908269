import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { DateTimeComponent } from 'app/modules-core/utility/date-time-picker/components/date-time.component';
import { DxDateBoxModule } from 'devextreme-angular';
import { DateComponent } from './components/date.component.';
import { TimeComponent } from './components/time.component';
import { DayAutocomplete } from './components/day-autocomplete.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MonthComponent } from './components/month.component';
import { YearComponent } from './components/year.component';
import { FinanceYearComponent } from './components/finance-year.component';



const routes = [
];

@NgModule({
  declarations: [
    DateTimeComponent,
    DateComponent,
    TimeComponent,
    DayAutocomplete,
    MonthComponent,
    YearComponent,
    FinanceYearComponent,
  ],
  entryComponents: [
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,

    FuseSharedModule,
    DxDateBoxModule,

    MaterialModule,
    NgSelectModule,
  ],
  exports: [
    DateTimeComponent,
    DateComponent,
    TimeComponent,
    DayAutocomplete,
    MonthComponent,
    YearComponent,
    FinanceYearComponent,
  ]
})

export class DateTimeModule {
}
