import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ApiService } from 'app/modules-core/api/api.service';
import { LOCAL_STORAGE_USER, LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_REDIRECT, LOCAL_STORAGE_APPLICATION_LIST } from 'app/storage.constants';
import { AuthService } from 'app/authentication/auth.service';
import { LoginSubjectService } from '../login-subject.service';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'app/modules-core/common/user/user.service';
import { QuickPanelSubjectService } from 'app/layout/components/quick-panel/quick-panel-subject.service';

@Component({
  selector: 'login-from-other-application',
  templateUrl: './login-from-other-application.component.html',
  styleUrls: ['./login-from-other-application.component.scss'],
  animations: fuseAnimations
})
export class LoginFromOtherApplicationComponent implements OnInit {
  loginForm: FormGroup;
  loginFormErrors: any;

  accessToken = null;
  email = null;
  fromAppName = null;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    private userService: UserService,
    private _route: ActivatedRoute,
    private _loginSubjectService: LoginSubjectService,
    private _quickPanelSubjectService: QuickPanelSubjectService,
  ) {
    this._route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.accessToken = params['accessToken'];
      this.fromAppName = params['fromAppName'];
    });
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        }
      }
    };

    // Set the defaults
    this.loginFormErrors = {
      email: {},
      password: {}
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.onSubmit();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  onSubmit() {
    let payload = {
      accessToken: this.accessToken,
      email: this.email,
      fromAppName: this.fromAppName,
    }
    this.apiService.post('users/login-from-other-application', payload)
      .subscribe(
        data => {
          window.localStorage[LOCAL_STORAGE_USER] = JSON.stringify(data);
          window.localStorage[LOCAL_STORAGE_TOKEN] = data.accessToken;

          this.authService.getCurrentUser(); //calling so that service class updates user data

          if (!this.authService.hasPermission('PERMISSION_MENU_OFFER_PRICE')) {
            this.userService.getOtherAppLinks({ 'email': data.email }).subscribe(res => {
              window.localStorage[LOCAL_STORAGE_APPLICATION_LIST] = JSON.stringify(res);
              this._quickPanelSubjectService.applicationListData.next(true);
            });
          }

          const redirect = window.localStorage[LOCAL_STORAGE_REDIRECT];
          if (redirect) {
            window.localStorage.removeItem(LOCAL_STORAGE_REDIRECT);
            this.router.navigateByUrl(redirect);
          }
          else {
            this.router.navigate(['/dashboard']);
          }
        },
        err => {
          console.log({ err });
        }
      );
  }


}
