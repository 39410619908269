import { TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import { SnackbarCustomComponent } from './snackbar-custom.component';
import * as _ from 'lodash';
import { ModalCustomComponent } from './modal-custom.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@angular/material/dialog";
var Helpers = /** @class */ (function () {
    function Helpers(snackbar, dialog) {
        this.snackbar = snackbar;
        this.dialog = dialog;
        this.matSnackBarConfDefault = {
            horizontalPosition: 'center',
            verticalPosition: 'top',
        };
    }
    Helpers.prototype.toast = function (msg) {
        return this.snackbar.open(msg, 'Close');
    };
    Helpers.prototype.toastErr = function (message, conf) {
        if (conf === void 0) { conf = null; }
        if (!message) {
            throw new Error('Custom snackbar - No message provided for showing in snackbar');
        }
        var confDefault = _.assign(this.matSnackBarConfDefault, {
            panelClass: ['red-50-bg', 'snackbar-custom'],
        });
        conf && _.merge(confDefault, conf);
        return this.snackbar.openFromComponent(SnackbarCustomComponent, _.merge(confDefault, {
            data: {
                message: message
            }
        }));
    };
    Helpers.prototype.toastFromTpl = function (tpl) {
        return this.snackbar.openFromTemplate(tpl);
    };
    Helpers.prototype.modalErr = function (errors, title) {
        if (title === void 0) { title = ''; }
        return this.dialog.open(ModalCustomComponent, {
            data: { errors: errors, title: title }
        });
    };
    Helpers.prototype.toastClose = function () {
        this.snackbar.dismiss();
    };
    Helpers.ngInjectableDef = i0.defineInjectable({ factory: function Helpers_Factory() { return new Helpers(i0.inject(i1.MatSnackBar), i0.inject(i2.MatDialog)); }, token: Helpers, providedIn: "root" });
    return Helpers;
}());
export { Helpers };
