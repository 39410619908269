import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatExpansionPanelDescription, MatSnackBarConfig, MatDialog } from '@angular/material';
import { SnackbarCustomComponent } from './snackbar-custom.component';
import * as _ from 'lodash';
import { ModalCustomComponent } from './modal-custom.component';

@Injectable({
  providedIn: 'root'
})
export class Helpers {
  constructor(
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  matSnackBarConfDefault = {
    horizontalPosition: 'center',
    verticalPosition: 'top',
  };

  toast(msg) {
    return this.snackbar.open(msg, 'Close');
  }

  toastErr(message, conf: MatSnackBarConfig = null) {
    if (!message) {
      throw new Error('Custom snackbar - No message provided for showing in snackbar');
    }

    let confDefault = _.assign(this.matSnackBarConfDefault, {
      panelClass: ['red-50-bg', 'snackbar-custom'],
    });

    conf && _.merge(confDefault, conf);

    return this.snackbar.openFromComponent(SnackbarCustomComponent, _.merge(confDefault, {
      data: {
        message: message
      }
    }));
  }

  toastFromTpl(tpl: TemplateRef<any>) {
    return this.snackbar.openFromTemplate(tpl);
  }

  modalErr(errors, title = '') {
    return this.dialog.open(ModalCustomComponent, {
      data: { errors, title }
    })
  }

  toastClose() {
    this.snackbar.dismiss();
  }

}