import { Subject, BehaviorSubject } from 'rxjs';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "../../../@fuse/components/sidebar/sidebar.service";
import * as i2 from "../../../@fuse/services/config.service";
var SidebarFilterContainerService = /** @class */ (function () {
    function SidebarFilterContainerService(_fuseSidebarService, _fuseConfigService) {
        this._fuseSidebarService = _fuseSidebarService;
        this._fuseConfigService = _fuseConfigService;
        this._currentFilterComponent = new Subject();
        this.currentFilterComponent$ = this._currentFilterComponent.asObservable();
        this.filterParams = new Subject();
        this.filterParams$ = this.filterParams.asObservable();
        this.reset = new BehaviorSubject(false);
        this.reset$ = this.reset.asObservable();
    }
    Object.defineProperty(SidebarFilterContainerService.prototype, "filters$", {
        get: function () {
            return this.filterParams$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SidebarFilterContainerService.prototype, "filterEmitter", {
        get: function () {
            return this.filterParams;
        },
        enumerable: true,
        configurable: true
    });
    SidebarFilterContainerService.prototype.setCurrentFilter = function (component) {
        var _this = this;
        this._currentFilterComponent.next(component);
        //this will show the icon to open the filter
        this._fuseConfigService.setConfig({
            hasSidebarFilter: true
        });
        //TODO - check when we can unsubscribe or switchMap
        this.filterParams$.subscribe(function (opts) {
            //this will animate the icon to open the filter
            //as visual indication that filters are active
            _this._fuseConfigService.setConfig({
                sidebarFiltersActive: !_.isEmpty(opts)
            });
        });
    };
    SidebarFilterContainerService.prototype.unsetfilter = function () {
        this._fuseConfigService.setConfig({
            hasSidebarFilter: false
        });
    };
    SidebarFilterContainerService.prototype.getCurrentFilter$ = function () {
        return this.currentFilterComponent$;
    };
    SidebarFilterContainerService.prototype.toggleSidebarOpen = function () {
        this._fuseSidebarService.getSidebar('themeOptionsPanel').toggleOpen();
    };
    SidebarFilterContainerService.ngInjectableDef = i0.defineInjectable({ factory: function SidebarFilterContainerService_Factory() { return new SidebarFilterContainerService(i0.inject(i1.FuseSidebarService), i0.inject(i2.FuseConfigService)); }, token: SidebarFilterContainerService, providedIn: "root" });
    return SidebarFilterContainerService;
}());
export { SidebarFilterContainerService };
