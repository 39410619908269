import { NgModule } from '@angular/core';
import { MatDividerModule, MatListModule, MatSlideToggleModule, MatIconModule, MatCardModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';
import { ButtonModule } from 'app/modules-core/utility/button/button.module';

@NgModule({
  declarations: [
    QuickPanelComponent
  ],
  imports: [
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    MatIconModule,
    FuseSharedModule,
    MatCardModule,
    ButtonModule,
  ],
  exports: [
    QuickPanelComponent
  ]
})
export class QuickPanelModule {
}
