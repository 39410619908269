import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/internal/operators/map";
import { makeParams } from "app/modules-core/utility/helpers";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class ContractService {
  urlBase = "contracts";
  urlBaseContractProductCategory = "contract-product-categories";
  urlBaseContractProduct = "contract-products";
  urlBaseContractLots = "contract-lots";
  urlBaseForWorkingCapitalApprove = "contracts/working-capital-approval";
  urlBaseForWorkingCapitalReceiveValue = "reports/working-capital";
  urlBaseForWorkingCapitalProcured = "reports/working-capital-procurement";
  urlBaseForWorkingCapitalCostDetail = "working-capital-cost-details";
  urlBaseForFileUpload = "contract-procurement-budgets";
  urlBaseForLoanWorkingCapital = "loan-working-capital";

  httpOptions = {
    params: new HttpParams(),
  };
  constructor(private http: HttpClient) { }

  getAll(opts = {}) {
    return this.http
      .get(`${this.urlBase}/v2`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => {
        resp.data = _.map(resp.data, contract => {
          contract['advance'] = _.chain(contract.contractPaymentTerms).find(['paymentTypeName', 'advance']).value();
          contract['progressive'] = _.chain(contract.contractPaymentTerms).find(['paymentTypeName', 'progressive']).value();
          contract['retention'] = _.chain(contract.contractPaymentTerms).filter(['paymentTypeName', 'retention']).value();
          contract['retentionFirst'] = _.head(contract['retention']);
          contract.retention.splice(0, 1);
          return contract;
        });
        return resp;
      }));
  }

  getContractsLight(opts = {}) {
    return this.http
      .get(`${this.urlBase}/light`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => {
        return resp.data;
      }));
  }

  getContractsLots(opts = {}) {
    return this.http
      .get(`${this.urlBaseContractLots}/light`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => {
        return resp.data;
      }));
  }

  get(id) {
    return this.http
      .get(`${this.urlBase}/${id}/v2`)
      .pipe(map((resp: any) => resp.data));
  }

  getDeliveryLot(id) {
    return this.http
      .get(`${this.urlBase}/${id}/generate-delivery-lot`)
      .pipe(map((resp: any) => resp.data));
  }

  create(payload) {
    return this.http
      .post(this.urlBase, payload)
      .pipe(map((resp: any) => resp.data));
  }

  update(payload) {
    return this.http
      .put(`${this.urlBase}`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  updateWorkingCapitalStatus(payload) {
    return this.http
      .put(`${this.urlBaseForWorkingCapitalApprove}`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  updateFields(payload) {
    return this.http
      .patch(`${this.urlBase}`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  delete(id, payload = {}) {
    return this.http
      .delete(`${this.urlBase}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  //Contract Product Category

  getAllContractProductCategory(opts = {}) {
    return this.http
      .get(`${this.urlBaseContractProductCategory}`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => resp.data));
  }

  getContractProductCategory(id) {
    return this.http
      .get(`${this.urlBaseContractProductCategory}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  createContractProductCategory(payload) {
    return this.http
      .post(this.urlBaseContractProductCategory, payload)
      .pipe(map((resp: any) => resp.data));
  }

  updateContractProductCategory(payload) {
    return this.http
      .put(`${this.urlBaseContractProductCategory}`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  updateContractProductCategoryFields(payload) {
    return this.http
      .patch(`${this.urlBaseContractProductCategory}`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  deleteContractProductCategory(id, payload = {}) {
    return this.http
      .delete(`${this.urlBaseContractProductCategory}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  getClientInfo(id, opts = {}) {
    return this.http
      .get(`${this.urlBaseContractProductCategory}/${id}/client-info`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => resp.data));
  }

  getNextLotNo(id) {
    return this.http
      .get(`${this.urlBaseContractProductCategory}/${id}/generate-lot`)
      .pipe(map((resp: any) => resp.data));
  }

  getPlanningNextLotNo(id) {
    return this.http
      .get(`${this.urlBaseContractProductCategory}/${id}/generate-lot?projection=true`)
      .pipe(map((resp: any) => resp.data));
  }

  getQuarterInfo(opts = {}) {
    return this.http
      .get(`${this.urlBaseContractProductCategory}/quarter-info`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => resp.data));
  }

  //Contract Product 

  getAllContractProduct(opts = {}) {
    return this.http
      .get(`${this.urlBaseContractProduct}`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => resp.data));
  }

  getContractProduct(id) {
    return this.http
      .get(`${this.urlBaseContractProduct}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  createContractProduct(payload) {
    return this.http
      .post(this.urlBaseContractProduct, payload)
      .pipe(map((resp: any) => resp.data));
  }

  updateContractProduct(payload) {
    return this.http
      .put(`${this.urlBaseContractProduct}`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  updateContractProductFields(payload) {
    return this.http
      .patch(`${this.urlBaseContractProduct}`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  deleteContractProduct(id) {
    return this.http
      .delete(`${this.urlBaseContractProduct}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }


  getBudgetHistories(id) {
    return this.http
      .get(`${this.urlBase}/${id}/budget-histories`)
      .pipe(map((resp: any) => resp.data));
  }


  getLotHistories(id, opts = {}) {
    return this.http
      .get(`${this.urlBase}/${id}/lot-histories`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => resp.data));
  }


  getAllContractLiteVersion(opts = {}) {
    return this.http
      .get(`${this.urlBase}/light`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => resp.data));
  }

  getAllBudgetSummary(id, opts = {}) {
    return this.http
      .get(`${this.urlBase}/${id}/budget-summaries`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => resp));
  }

  getContractProductCategoryLotHistories(id, opts = {}) {
    return this.http
      .get(`${this.urlBaseContractProductCategory}/${id}/lots`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => resp.data));
  }

  getWorkingCapitalPaymentReceive(id) {
    return this.http
      .get(`${this.urlBaseForWorkingCapitalReceiveValue}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }
  getWorkingCapitalProcured(id) {
    return this.http
      .get(`${this.urlBaseForWorkingCapitalProcured}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  createWorkingCapitalCostDetail(payload) {
    return this.http
      .post(this.urlBaseForWorkingCapitalCostDetail, payload)
      .pipe(map((resp: any) => resp.data));
  }

  getWorkingCapitalCostDetail(id, opts = {}) {
    return this.http
      .get(`${this.urlBaseForWorkingCapitalCostDetail}/contracts/${id}`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => resp));
  }

  getById(id) {
    return this.http
      .get(`${this.urlBase}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  updateOtherCostDetail(payload) {
    return this.http
      .patch(`${this.urlBaseForWorkingCapitalCostDetail}`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  deleteCostData(id, payload = {}) {
    return this.http
      .delete(`${this.urlBaseForWorkingCapitalCostDetail}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  uploadBudgetFile(data, param) {
    return this.http
      .post(`${this.urlBaseForFileUpload}/excel-upload?contractid=${param.contractid}`, data)
      .pipe(map((resp: any) => resp.data));
  }

  addFinanceAmount(payload) {
    return this.http
      .post(this.urlBaseForLoanWorkingCapital, payload)
      .pipe(map((resp: any) => resp.data));
  }

  getWCLoans(id, opts = {}) {
    return this.http
      .get(`${this.urlBaseForLoanWorkingCapital}/contract/${id}`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params,
            },
            { queryParams: opts }
          )
        ),
      })
      .pipe(map((resp: any) => resp));
  }

  updateWorkingCapitalLoan(payload, id) {
    return this.http
      .patch(`${this.urlBaseForLoanWorkingCapital}`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  deleteworkingCapitalLoan(id) {
    return this.http
      .delete(`${this.urlBaseForLoanWorkingCapital}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  getInventoryValues(id) {
    return this.http
      .get(`reports/current-inventory-status/${id}`)
      .pipe(map((resp: any) => resp.data));
  }
  getInventoryStatus(id) {
    return this.http
      .get(`reports/current-working-capital/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  updateSVFCcostPercentage(id, payload) {
    return this.http
      .patch(`${this.urlBase}/${id}/update-svfc-percentages`, payload)
      .pipe(map((resp: any) => resp.data));
  }

  svfcMonthlyAvgCost(id) {
    return this.http
      .get(`reports/svfc-monthly-cost-average/${id}`)
      .pipe(map((resp: any) => resp));
  }
  svfcMonthlyActualCost(id) {
    return this.http
      .get(`reports/svfc-monthly-cost-actual/${id}`)
      .pipe(map((resp: any) => resp));
  }
}
