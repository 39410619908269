<!-- <div fxLayout="column"
  fxFlex
  fusePerfectScrollbar> -->

<mat-list>
  <h3 matSubheader
    style="border-bottom: 1px solid #ccc;">
    <span>Notifications
      <span class="cqbadge"
        *ngIf="countNotification > 0">{{countNotification}}</span>
    </span>
  </h3>


  <!-- <mat-list-item *ngFor="let item of notificationList"
    style="padding: 0px 10px !important"> -->
  <div *ngFor="let item of notificationList; let i = index">
    <div class="notification-card"
      [ngClass]="{'new' : item.statusName == 'new'}">
      <div>
        <span class="date">{{item?.createdDate|date: 'mediumDate'}}</span>
      </div>
      <div fxLayout="row">
        <div fxFlex>
          <span class="message"><span *ngIf="item.responsibleUserName"
              style="font-weight: bold;">{{item.responsibleUserName}}:</span>
            {{ (item.text.length>60)? (item.text | slice:0:60)+'...':(item.text) }}</span>
        </div>
        <div fxFlex="20px;">
          <mat-icon class="s-12 red-400-fg cursor-pointer"
            (click)="removeNotification(item.id)">remove_circle_outline
          </mat-icon>
          <mat-icon matTooltip="Mark as Read"
            *ngIf="item?.statusName == 'new'"
            class="s-12 blue-700-fg cursor-pointer"
            (click)="updateAsViewNotification(item.id, 'view', i)">
            remove_red_eye
          </mat-icon>
          <mat-icon matTooltip="Mark as Unread"
            *ngIf="item?.statusName == 'view'"
            class="s-12 cursor-pointer"
            (click)="updateAsViewNotification(item.id, 'new', i)">remove_red_eye
          </mat-icon>
        </div>
      </div>
      <!-- <div>
        <btn-small [custom]="'Details'"
          [iconHide]="true"></btn-small>
        <btn-small style="float: right;"
          [text]="'clear'"
          [iconHide]="true"
          (onclick)="updateAsViewNotification(item.id)"></btn-small>
      </div> -->
    </div>
  </div>
</mat-list>


<!-- <mat-list class="date"
    cdkFocusRegionStart> -->
<!-- 
  <h3 matSubheader
    cdkFocusInit>
    <span>Notifications</span>
  </h3>


  <span *ngFor="let item of notificationList">
    {{item?.text}}
    <mat-divider cdkFocusRegionEnd></mat-divider>

  </span> -->

<!-- <h3 matSubheader
      cdkFocusInit>
      <span>Today</span>
    </h3>

    <div class="secondary-text mat-display-1 mb-0 p-16">
      <div class="mb-12">
        {{date | date:'EEEE'}}
      </div>
      <div fxLayout="row"
        fxLayoutAlign="start start">
        <span> {{date | date:'d'}}</span>
        <span class="mat-subheading-1">th</span>
        <span> {{date | date:'MMMM'}}</span>
      </div>
    </div>
  </mat-list>

  <mat-divider cdkFocusRegionEnd></mat-divider> -->

<!-- <mat-list>
        <h3 matSubheader>
            <span>Events</span>
        </h3>

        <mat-list-item *ngFor="let event of events">
            <h3 matLine>{{event.title}}</h3>
            <p matLine class="secondary-text">{{event.detail}}</p>
        </mat-list-item>
    </mat-list>

    <mat-divider></mat-divider>

    <mat-list>
        <h3 matSubheader>
            <span>Notes</span>
        </h3>

        <mat-list-item *ngFor="let note of notes">
            <h3 matLine>{{note.title}}</h3>
            <p matLine class="secondary-text">{{note.detail}}</p>
        </mat-list-item>
    </mat-list>

    <mat-divider></mat-divider>

    <mat-list>
        <h3 matSubheader>
            <span>Quick Settings</span>
        </h3>

        <mat-list-item>
            <mat-slide-toggle fxFlex class="mat-primary" [(ngModel)]="settings.notify" aria-label="Notifications"
                              labelPosition="before">
                <h3>Notifications</h3>
            </mat-slide-toggle>
        </mat-list-item>

        <mat-list-item>
            <mat-slide-toggle fxFlex class="mat-accent" [(ngModel)]="settings.cloud" aria-label="Cloud"
                              labelPosition="before">
                <h3>Cloud Sync</h3>
            </mat-slide-toggle>
        </mat-list-item>

        <mat-list-item>
            <mat-slide-toggle fxFlex class="mat-warn" [(ngModel)]="settings.retro" aria-label="Retro Thrusters"
                              labelPosition="before">
                <h3>Retro Thrusters</h3>
            </mat-slide-toggle>
        </mat-list-item>
    </mat-list> -->

<!-- </div> -->