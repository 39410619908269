import { MatSnackBar } from "@angular/material";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
var SnackbarService = /** @class */ (function () {
    function SnackbarService(snackBar) {
        this.snackBar = snackBar;
        this.defaultConfig = {
            duration: 3000,
            panelClass: null,
        };
    }
    SnackbarService.prototype.open = function (message, action, actionType, disabledAutoHide) {
        if (actionType === void 0) { actionType = null; }
        if (disabledAutoHide === void 0) { disabledAutoHide = false; }
        if (actionType == 'success') {
            this.defaultConfig.panelClass = 'snackbar-success';
        }
        else if (actionType == 'warning') {
            this.defaultConfig.panelClass = 'snackbar-warning';
        }
        else if (actionType == 'error') {
            this.defaultConfig.panelClass = 'snackbar-error';
        }
        else if (actionType == 'backend-error') {
            this.defaultConfig.panelClass = 'snackbar-backend-error';
        }
        else {
            this.defaultConfig.panelClass = null;
        }
        disabledAutoHide ? this.defaultConfig.duration = null : null;
        this.snackBar.open(message, action, this.defaultConfig);
    };
    SnackbarService.ngInjectableDef = i0.defineInjectable({ factory: function SnackbarService_Factory() { return new SnackbarService(i0.inject(i1.MatSnackBar)); }, token: SnackbarService, providedIn: "root" });
    return SnackbarService;
}());
export { SnackbarService };
