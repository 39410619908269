<div id="login"
  fxLayout="column">

  <div id="login-form-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center">

    <div id="login-form"
      [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

      <div class="logo">
        <img src="assets/images/logos/logo_pq.png">
      </div>

      <div class="title"
        style="margin-bottom: -10px;color: #039be5;">CONFIDENCE CCMIS
      </div>
      <div class="title">LOGIN TO YOUR ACCOUNT</div>

      <form name="loginForm"
        [formGroup]="loginForm"
        novalidate>

        <mat-form-field>
          <input matInput
            placeholder="Email"
            formControlName="email"
            autofocus>
          <mat-error *ngIf="loginFormErrors.email.required">
            Email is required
          </mat-error>
          <mat-error
            *ngIf="!loginFormErrors.email.required && loginFormErrors.email.email">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <!-- <mat-form-field>
          <input matInput
            placeholder="User Name"
            formControlName="userName"
            autofocus>
          <mat-error *ngIf="loginFormErrors.userName.required">
            User Name is required
          </mat-error>
          <mat-error
            *ngIf="!loginFormErrors.email.required && loginFormErrors.email.email">
            Please enter a valid email address
          </mat-error>
        </mat-form-field> -->

        <mat-form-field>
          <input matInput
            type="password"
            placeholder="Password"
            formControlName="password">
          <mat-error *ngIf="loginFormErrors.password.required">
            Password is required
          </mat-error>
        </mat-form-field>

        <div class="remember-forgot-password"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="space-between center">
          <mat-checkbox class="remember-me"
            aria-label="Remember Me">
            Remember Me
          </mat-checkbox>

          <!-- <a class="forgot-password"
            [routerLink]="'/forgot-password'">Forgot Password?</a> -->
          <!-- <a class="forgot-password">
                        Forgot Password?
                    </a> -->
        </div>

        <button mat-raised-button
          color="accent"
          class="submit-button"
          aria-label="LOG IN"
          (click)="onSubmit()"
          [disabled]="loginForm.invalid">
          LOGIN
        </button>

      </form>

      <!-- <div class="separator">
                <span class="text">OR</span>
            </div>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Don't have an account?</span>
                <a class="link" [routerLink]="'/pages/auth/register'">Create an account</a>
            </div> -->

    </div>

  </div>

</div>