import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ApiService } from 'app/modules-core/api/api.service';
import { LOCAL_STORAGE_USER, LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_REDIRECT } from 'app/storage.constants';
import { AuthService } from 'app/authentication/auth.service';
import { LoginSubjectService } from '../login-subject.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'direct-login',
  templateUrl: './direct-login.component.html',
  styleUrls: ['./direct-login.component.scss'],
  animations: fuseAnimations
})
export class DirectLoginComponent implements OnInit {
  loginForm: FormGroup;
  loginFormErrors: any;

  token = null;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    private _route: ActivatedRoute,
    private _loginSubjectService: LoginSubjectService,
  ) {
    this.token = this._route.snapshot.params["token"];
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        }
      }
    };

    // Set the defaults
    this.loginFormErrors = {
      email: {},
      password: {}
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.onSubmit();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  onSubmit() {
    let payload = { token: this.token }
    this.apiService.post('users/login/direct', payload)
      .subscribe(
        data => {
          window.localStorage[LOCAL_STORAGE_USER] = JSON.stringify(data);
          window.localStorage[LOCAL_STORAGE_TOKEN] = data.accessToken;

          this.authService.getCurrentUser(); //calling so that service class updates user data

          const redirect = window.localStorage[LOCAL_STORAGE_REDIRECT];
          if (redirect) {
            window.localStorage.removeItem(LOCAL_STORAGE_REDIRECT);
            this.router.navigateByUrl(redirect);
          }
          else if (this.authService.hasPermission('PERMISSION_MENU_OFFER_PRICE')) {
            this.router.navigate(['/offer-price']);
          }
        },
        err => {
          console.log({ err });
        }
      );
  }


}
