import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import * as i0 from "@angular/core";
var QuickPanelSubjectService = /** @class */ (function () {
    function QuickPanelSubjectService() {
        this.subject = new Subject();
        this.notification = new BehaviorSubject({});
        this.notification$ = this.notification.asObservable();
        this.heading = new BehaviorSubject({});
        this.heading$ = this.heading.asObservable();
        this.previousMonthData = new BehaviorSubject(false);
        this.previousMonthData$ = this.previousMonthData.asObservable();
        this.nextMonthData = new BehaviorSubject(false);
        this.nextMonthData$ = this.nextMonthData.asObservable();
        this.applicationListData = new BehaviorSubject(false);
        this.applicationListData$ = this.applicationListData.asObservable();
    }
    QuickPanelSubjectService.ngInjectableDef = i0.defineInjectable({ factory: function QuickPanelSubjectService_Factory() { return new QuickPanelSubjectService(); }, token: QuickPanelSubjectService, providedIn: "root" });
    return QuickPanelSubjectService;
}());
export { QuickPanelSubjectService };
