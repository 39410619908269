import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { LoginComponent } from 'app/authentication/login/login.component';
import { DirectLoginComponent } from './direct-login/direct-login.component';
import { LoginFromOtherApplicationComponent } from './login-from-other-application/login-from-other-application.component';

const routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'direct/token/:token',
        component: DirectLoginComponent
    },
    {
        path: 'login-from-other-application',
        component: LoginFromOtherApplicationComponent
    }
];

@NgModule({
    declarations: [
        LoginComponent,
        DirectLoginComponent,
        LoginFromOtherApplicationComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,

        FuseSharedModule
    ]
})
export class LoginModule {
}
