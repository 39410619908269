import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ApiService } from 'app/modules-core/api/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackbarService } from 'app/modules-core/utility/snackbar.service';

@Component({
  selector: 'password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.scss'],
  animations: fuseAnimations
})
export class PasswordResetFormComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  forgotPasswordFormErrors: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */

  data = <any>{
    password: null,
    confirmPassword: null
  }
  queryParams = <any>{}

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    private snackBar: SnackbarService,
    private _router: Router,
    private route: ActivatedRoute,
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        }
      }
    };

    // Set the defaults
    this.forgotPasswordFormErrors = {
      email: {}
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.queryParams = _.merge({}, _.get(this.route, 'snapshot.queryParams'));
  }



  resetPassword() {
    if (!this.data.password) {
      this.snackBar.open(`please enter password!`, 'Close');
    }
    let payload = {
      accessToken: _.get(this.queryParams, 'token'),
      password: this.data.password
    };
    this.apiService.putApi('users/reset-password', payload)
      .subscribe(
        data => {
          this.data = <any>{
            password: null
          }
          this.snackBar.open(`password change successfully!`, 'Close');
          this._router.navigate(['login']);
        });
  }
}
