export const locale = {
  lang: "en",
  data: {
    NAV: {
      APPLICATIONS: "App",
      MERCHANDISING: "Marchandising",
      DASHBOARD: "Dashboard",
      SNIPPET: "Snippet",
      USERS: {
        TITLE: "Users"
      },
      BUYERS: {
        TITLE: "Buyers"
      },
      STYLES: {
        TITLE: "Styles"
      },
      ORDERS: {
        TITLE: "Orders"
      },
      COSTREVIEW: {
        TITLE: "Cost Review"
      },
      TNA: {
        TITLE: "TNA"
      },
      PIS: {
        TITLE: "PIs"
      },
      CALENDAR: {
        TITLE: "Calendar"
      },
      PLANNING: {
        MICRO_PLAN: "Micro Plan",
      },
      PRODUCTION: {
        PRODUCTION: "Production"
      },
      WORK_ORDERS: {
        TITLE: "Work Orders"
      },
      REPORT: {
        RESOURCE_WISE_PLAN_PRODUCTION: "Resource Wise Plan Production",
        ORDER_WISE_PLAN_PRODUCTION: "Order Wise Plan Production",
        ORDER_PLAN: "Order Plan",
        ORDER_TNA: "Order TNA",
        DAILY_PLAN_PRODUCTION: "Daily Plan Production",
      },
      INVENTORY: "Inventory",
      COMMERCIAL: {
        TITLE: "Commercial",
        LC: 'LC',
        LCB2B: 'B2B LC',
      },
      SUPPLIERS: {
        TITLE: "Suppliers"
      }
    }
  }
};
