/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./style-1.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../@fuse/components/navigation/navigation.component.ngfactory";
import * as i4 from "../../../../../../@fuse/components/navigation/navigation.component";
import * as i5 from "../../../../../authentication/auth.service";
import * as i6 from "../../../../../../@fuse/components/navigation/navigation.service";
import * as i7 from "../../../../../authentication/login/login-subject.service";
import * as i8 from "./style-1.component";
import * as i9 from "../../../../../../@fuse/services/config.service";
import * as i10 from "../../../../../../@fuse/components/sidebar/sidebar.service";
var styles_NavbarHorizontalStyle1Component = [i0.styles];
var RenderType_NavbarHorizontalStyle1Component = i1.ɵcrt({ encapsulation: 2, styles: styles_NavbarHorizontalStyle1Component, data: {} });
export { RenderType_NavbarHorizontalStyle1Component as RenderType_NavbarHorizontalStyle1Component };
export function View_NavbarHorizontalStyle1Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fuse-navigation", [["layout", "horizontal"]], null, null, null, i3.View_FuseNavigationComponent_0, i3.RenderType_FuseNavigationComponent)), i1.ɵdid(3, 114688, null, 0, i4.FuseNavigationComponent, [i1.ChangeDetectorRef, i5.AuthService, i6.FuseNavigationService, i7.LoginSubjectService], { layout: [0, "layout"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fuseConfig.layout.navbar.primaryBackground; _ck(_v, 1, 0, currVal_0); var currVal_1 = "horizontal"; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_NavbarHorizontalStyle1Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navbar-horizontal-style-1", [], null, null, null, View_NavbarHorizontalStyle1Component_0, RenderType_NavbarHorizontalStyle1Component)), i1.ɵdid(1, 245760, null, 0, i8.NavbarHorizontalStyle1Component, [i9.FuseConfigService, i6.FuseNavigationService, i10.FuseSidebarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarHorizontalStyle1ComponentNgFactory = i1.ɵccf("navbar-horizontal-style-1", i8.NavbarHorizontalStyle1Component, View_NavbarHorizontalStyle1Component_Host_0, {}, {}, []);
export { NavbarHorizontalStyle1ComponentNgFactory as NavbarHorizontalStyle1ComponentNgFactory };
