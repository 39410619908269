import { Component, ViewEncapsulation } from '@angular/core';
import { QuickPanelService } from './quick-panel.service';
import { Message } from '@stomp/stompjs';
import * as _ from 'lodash';
import { AuthService } from 'app/authentication/auth.service';
import { QuickPanelSubjectService } from './quick-panel-subject.service';
import { LoginSubjectService } from 'app/authentication/login/login-subject.service';
import { SnackbarService } from 'app/modules-core/utility/snackbar.service';
@Component({
  selector: 'quick-panel',
  templateUrl: './quick-panel.component.html',
  styleUrls: ['./quick-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent {
  date: Date;
  events: any[];
  notes: any[];
  settings: any;
  notificationList = null;
  Notification$;
  notificationSubscription;
  countNotification = 0;
  userId = null;
  notificationsFromWebSocket: any[] = [];
  /**
   * Constructor
   */
  constructor(
    private _quickPanelService: QuickPanelService,
    private snackBar: SnackbarService,
    public _authService: AuthService,
    private _quickPanelSubjectService: QuickPanelSubjectService,
    private _loginSubjectService: LoginSubjectService,
  ) {
    // Set the defaults
    this.date = new Date();
    this.settings = {
      notify: true,
      cloud: false,
      retro: true
    };
  }

  ngOnInit() {
    // if (window.localStorage[LOCAL_STORAGE_TOKEN]) {
    //   this.getNotification();
    //   this.getNotificationsFromWebsocket();
    // }
    // this._loginSubjectService.loggedIn$.subscribe((data) => {
    //   this.getNotification();
    //   this.getNotificationsFromWebsocket();
    // });

  }

  getNotification() {
    this._quickPanelService.getAllNotification({ statusname: 'new,view' }).subscribe(res => {
      this.notificationList = _.orderBy(res, ['createdDate'], ['desc']);;
      this.setNoticiationCounter();
    })
  }

  // getNotificationsFromWebsocket() {
  //   this.userId = this._authService.getCurrentUser().id;
  //   this.Notification$ = this.websocketService.subscribeToNotifications();
  //   this.notificationSubscription = this.Notification$.subscribe((message: Message) => {
  //     try {
  //       let notification = JSON.parse(message.body);

  //       if (notification.userId === this.userId) {
  //         let data = [];
  //         data.push(notification);
  //         this.notificationList = _.concat(data, this.notificationList);
  //         this.setNoticiationCounter();

  //         //this.notificationList.push(notification);
  //       }
  //     } catch (err) {
  //       console.log('websocket payload JSON.parse failed, err: ', err);
  //     }
  //   });
  // }

  updateAsViewNotification(id, statusName, index) {
    let data = {
      id: id,
      statusName: statusName
    }
    this._quickPanelService.updateNotification([data]).subscribe(res => {
      this.notificationList[index]['statusName'] = statusName;
      this.setNoticiationCounter();
    })
  }

  removeNotification(id) {
    let data = {
      id: id,
      statusName: 'delete'
    }
    this._quickPanelService.updateNotification([data]).subscribe(res => {
      _.chain(this.notificationList)
        .remove({ 'id': id })
        .value();
      this.setNoticiationCounter();
    })
  }

  setNoticiationCounter() {
    var newList = _.filter(this.notificationList, ['statusName', 'new']);
    this._quickPanelSubjectService.notification.next(newList.length);
    this.countNotification = newList.length;
  }
}
