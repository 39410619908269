import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AuthService } from 'app/authentication/auth.service';
import * as _ from 'lodash';
import { LoginSubjectService } from 'app/authentication/login/login-subject.service';

import { navigation as NAV } from 'app/navigation/navigation';
import { LOCAL_STORAGE_USER } from 'app/storage.constants';


@Component({
  selector: 'fuse-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
  @Input()
  layout = 'vertical';

  @Input()
  navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {FuseNavigationService} _fuseNavigationService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    public _auth: AuthService,
    private _fuseNavigationService: FuseNavigationService,
    private _loginSubjectService: LoginSubjectService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Load the navigation either from the input or from the service
    this.navigation = this.navigation || this.getNavigationByPermissionCheck();

    // // Subscribe to the current navigation changes
    // this._fuseNavigationService.onNavigationChanged
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe(() => {

    //     // Load the navigation
    //     this.navigation = this.getNavigationByPermissionCheck();

    //     // Mark for check
    //     this._changeDetectorRef.markForCheck();
    //   });

    // // Subscribe to navigation item
    // merge(
    //   this._fuseNavigationService.onNavigationItemAdded,
    //   this._fuseNavigationService.onNavigationItemUpdated,
    //   this._fuseNavigationService.onNavigationItemRemoved
    // ).pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe(() => {

    //     // Mark for check
    //     this._changeDetectorRef.markForCheck();
    //   });
    this._loginSubjectService.loggedIn$.subscribe((data) => {
      // Load the navigation
      // var currentUserData = JSON.parse(window.localStorage[LOCAL_STORAGE_USER]);

      this.navigation = this.getNavigationByPermissionCheck();

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  getNavigationByPermissionCheck() {
    //var navigation = this._fuseNavigationService.getCurrentNavigation();
    var navigation = _.merge([], NAV);
    //var currentUserData = JSON.parse(window.localStorage[LOCAL_STORAGE_USER]);

    navigation = _.map(navigation, module => {
      if (!this.checkPermission(module)) {
        return false;
      }
      module.children = _.map(module.children, parent => {
        if (!this.checkPermission(parent)) {
          return false;
        }
        parent.children = _.map(parent.children, child => {
          if (!this.checkPermission(child)) {
            return false;
          }
          child.children = _.map(child.children, grandchild => {
            if (!this.checkPermission(grandchild)) {
              return false;
            }
            return grandchild;
          });

          child.children = _.compact(child.children);
          if (child.type != "item" && !child.children.length) {
            return false;
          }
          return child;
        });
        parent.children = _.compact(parent.children);
        if (parent.type != "item" && !parent.children.length) {
          return false;
        }
        return parent;
      });

      module.children = _.compact(module.children);
      if (module.type != "item" && !module.children.length) {
        return false;
      }
      return module;
    });
    return navigation;
  }

  checkPermission(object) {
    if ('permission' in object) {
      if (this._auth.hasPermission(object.permission)) {
        return true;
      }
      return false;
    }
    return true;
  }

}
