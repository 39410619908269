import { NgModule } from '@angular/core';
import { MaterialModule } from 'app/modules-core/utility/material.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoginCheckRouteGuard } from 'app/authentication/check-login-route-guard';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'app/modules-core/utility/button/button.module';
import { NgxSummernoteModule } from 'ngx-summernote';
import { DxTreeListModule } from 'devextreme-angular';
import { DiaryModalComponent } from './components/diary-modal/diary-modal.component';
import { EditorModule } from 'primeng/editor';

// const routes = [
//   {
//     path: 'diaries',
//     component: DiaryListComponent,
//     canActivate: [LoginCheckRouteGuard],
//   }
// ];
@NgModule({
  declarations: [
    DiaryModalComponent,
  ],
  entryComponents: [
    DiaryModalComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    //RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MaterialModule,
    ButtonModule,
    NgxSummernoteModule,
    EditorModule
  ],
  exports: [
  ]
})

export class DiaryModule {
}
