import { Component, OnInit, Inject, Directive, HostListener } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'snackbar-custom',
  template: `
  <style>
    /* @TODO - check why not working
    :host-context(.mat-snack-bar-container) {
      border: 1px solid #ea9796;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    */
  </style>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span class="red-500-fg">
    {{ data.message }}
    </span>

    <mat-icon class="cursor-pointer red-400-fg" (click)="dismiss()">
      close
    </mat-icon>
  </div>
  `,
})
export class SnackbarCustomComponent implements OnInit {
  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }

  ngOnInit(): void { }

  dismiss() {
    this.snackBar.dismiss();
  }
}

@Directive({
  selector: '[snackbar-dismiss]'
})
export class SnackBarDismissDirective {
  constructor(
    private snackbar: MatSnackBar,
  ) { }

  @HostListener('click', ['$event'])
  onClick($event) {
    this.snackbar.dismiss();
  }
}

