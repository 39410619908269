
  <style>
    /* @TODO - check why not working
    :host-context(.mat-snack-bar-container) {
      border: 1px solid #ea9796;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    */
  </style>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span class="red-500-fg">
    {{ data.message }}
    </span>

    <mat-icon class="cursor-pointer red-400-fg" (click)="dismiss()">
      close
    </mat-icon>
  </div>
  