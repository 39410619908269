/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/flex-layout/flex";
import * as i2 from "@angular/flex-layout/core";
import * as i3 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./snackbar-custom.component";
import * as i6 from "@angular/material/snack-bar";
var styles_SnackbarCustomComponent = [""];
var RenderType_SnackbarCustomComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SnackbarCustomComponent, data: {} });
export { RenderType_SnackbarCustomComponent as RenderType_SnackbarCustomComponent };
export function View_SnackbarCustomComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["fxLayout", "row"], ["fxLayoutAlign", "space-between center"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i1.LayoutDirective, [i2.MediaMonitor, i0.ElementRef, i2.StyleUtils], { layout: [0, "layout"] }, null), i0.ɵdid(2, 737280, null, 0, i1.LayoutAlignDirective, [i2.MediaMonitor, i0.ElementRef, [6, i1.LayoutDirective], i2.StyleUtils], { align: [0, "align"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "red-500-fg"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "cursor-pointer red-400-fg mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i0.ɵdid(6, 9158656, null, 0, i4.MatIcon, [i0.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i0.ɵted(-1, 0, [" close "]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-between center"; _ck(_v, 2, 0, currVal_1); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data.message; _ck(_v, 4, 0, currVal_2); var currVal_3 = i0.ɵnov(_v, 6).inline; _ck(_v, 5, 0, currVal_3); }); }
export function View_SnackbarCustomComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "snackbar-custom", [], null, null, null, View_SnackbarCustomComponent_0, RenderType_SnackbarCustomComponent)), i0.ɵdid(1, 114688, null, 0, i5.SnackbarCustomComponent, [i6.MatSnackBar, i6.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SnackbarCustomComponentNgFactory = i0.ɵccf("snackbar-custom", i5.SnackbarCustomComponent, View_SnackbarCustomComponent_Host_0, {}, {}, []);
export { SnackbarCustomComponentNgFactory as SnackbarCustomComponentNgFactory };
