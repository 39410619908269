import { NgModule } from "@angular/core";
import { CommonSharedModule } from "./common/common.module";
import { UtilityModule } from "./utility/utility.module";
import { ApiModule } from "./api/api.module";
import { AuthModule } from "app/authentication/auth.module";
import {
  Routes,
  RouterModule
} from "@angular/router";
import { GeneralModule } from "./general/general.module";

const routes: Routes = [
  {
    path: "",
    loadChildren: "app/modules-core/dashboard/dashboard.module#DashboardModule"
  },
  {
    path: "",
    loadChildren:
      "app/modules-core/principal/principal.module#PrincipalModule"
  },
  {
    path: "",
    loadChildren: "app/modules-core/profile/profile.module#ProfileModule"
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonSharedModule,
    UtilityModule,
    GeneralModule,
    ApiModule,
    AuthModule,
    // ProfileModule

    //Tmp - Sidebar filters - entryComponents in lazy loaded modules not working
    //create a separate module and add here
  ],
  exports: [
  ]
})
export class CoreModules { }
