<h2 mat-dialog-title>Diary</h2>

<mat-dialog-content class="scrollable"
  style="min-height: 35vh;">
  <div class="content-card mat-white-bg">
    <div fxLayout
      fxLayoutGap="30px">
      <div fxFlex>
        <!-- <div [ngxSummernote]
          [(ngModel)]="note">
        </div> -->

        <div>
          <p-editor [(ngModel)]="note"
            [style]="{'height':'390px'}"></p-editor>
          <!-- <p>Value: {{note||'empty'}}</p> -->
        </div>
      </div>
    </div>
    <!-- <span style="float: right; margin-top: 10px;">
      
    </span> -->
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <btn [text]="'close'"
    [modalClose]="true">
  </btn>
  <btn [text]="'save'"
    (click)="addDiary()"></btn>
</mat-dialog-actions>