import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewChild,
  ComponentRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { SidebarFilterContainerService } from './sidebar-filter-container.service';

@Component({
  selector: 'sidebar-filter-container',
  template: `
    <div class="theme-options-panel" fusePerfectScrollbar>
      <ng-container #content></ng-container>
    </div>
  `,
  styleUrls: ['./sidebar-filter-container.scss']
})
export class SidebarFilterContainerComponent implements OnInit {
  @ViewChild('content', { read: ViewContainerRef })
  content: ViewContainerRef;

  private componentRef: ComponentRef<any>;

  constructor(
    private cfr: ComponentFactoryResolver,
    private sidebarService: SidebarFilterContainerService
  ) { }

  ngOnInit(): void {
    this.sidebarService.getCurrentFilter$().subscribe(filterComponent => {
      if (filterComponent) {
        const factory = this.cfr.resolveComponentFactory(filterComponent);

        this.content.clear();
        this.componentRef = this.content.createComponent(factory, 0);
      }
    });

  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

}
