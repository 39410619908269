import { Component, EventEmitter, Output, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'btn-base',
  template: ``,
})

export class BtnBaseComponent {

  @Input()
  text: string = 'save';

  @Input()
  disabled: any = false;

  @Input()
  custom: string = null;

  @Input()
  iconHide = false;

  @Input()
  icon: string = null;

  @Input()
  faicon: string = null;

  @Input()
  modalClose: boolean = null;

  @Output()
  onclick: EventEmitter<any> = new EventEmitter<any>();

  details: any = {
    submit: {
      icon: 'subdirectory_arrow_left',
      text: 'Submit',
      class: 'btn-submit',
      iconclass: 'btn-icon-submit',
      smallclass: 'btn-small-submit',
    },
    save: {
      icon: 'check_circle',
      text: 'Save',
      class: 'btn-save',
      iconclass: 'btn-icon-save',
      smallclass: 'btn-small-save',
    },
    add: {
      icon: 'add_circle_outline',
      text: 'Add',
      class: 'btn-add',
      iconclass: 'btn-icon-add',
      smallclass: 'btn-small-add',
    },
    copy: {
      icon: 'filter_none',
      text: 'Copy',
      class: 'btn-copy',
      iconclass: 'btn-icon-copy',
      smallclass: 'btn-small-copy',
    },
    addclose: {
      icon: 'add_circle_outline',
      text: 'Add & Close',
      class: 'btn-addclose',
      iconclass: 'btn-icon-addclose',
      smallclass: 'btn-small-addclose',
    },
    edit: {
      icon: 'edit',
      text: 'Edit',
      class: 'btn-edit',
      iconclass: 'btn-icon-edit',
      smallclass: 'btn-small-edit',
    },
    update: {
      icon: 'update',
      text: 'Update',
      class: 'btn-update',
      iconclass: 'btn-icon-update',
      smallclass: 'btn-small-update',
    },
    delete: {
      icon: 'cancel',
      text: 'Delete',
      class: 'btn-delete',
      iconclass: 'btn-icon-delete',
      smallclass: 'btn-small-delete',
    },
    clear: {
      icon: 'cached',
      text: 'Clear',
      class: 'btn-clear',
      iconclass: 'btn-icon-clear',
      smallclass: 'btn-small-clear',
    },
    close: {
      icon: 'block',
      text: 'Close',
      class: 'btn-close',
      iconclass: 'btn-icon-close',
      smallclass: 'btn-small-close',
    },
    cancel: {
      icon: 'not_interested',
      text: 'Cancel',
      class: 'btn-cancel',
      iconclass: 'btn-icon-cancel',
      smallclass: 'btn-small-cancel',
    },
    link: {
      icon: 'link',
      text: 'link',
      class: 'btn-link',
      iconclass: 'btn-icon-link',
      smallclass: 'btn-small-link',
    },
    expend: {
      icon: 'launch',
      text: 'Expend',
      class: 'btn-expend',
      iconclass: 'btn-icon-expend',
      smallclass: 'btn-small-expend',
    },
    dropdown: {
      icon: 'arrow_drop_down_circle',
      text: 'Drop Down',
      class: 'btn-dropdown',
      iconclass: 'btn-icon-dropdown',
      smallclass: 'btn-small-dropdown',
    },
    arrowright: {
      icon: 'chevron_right',
      text: 'Next',
      class: 'btn-arrowright',
      iconclass: 'btn-icon-arrowright',
      smallclass: 'btn-small-arrowright',
    },
    arrowleft: {
      icon: 'chevron_left',
      text: 'Previous',
      class: 'btn-arrowleft',
      iconclass: 'btn-icon-arrowleft',
      smallclass: 'btn-small-arrowleft',
    },
    archive: {
      icon: 'archive',
      text: 'Archive',
      class: 'btn-archive',
      iconclass: 'btn-icon-archive',
      smallclass: 'btn-small-archive',
    },
    unarchive: {
      icon: 'unarchive',
      text: 'Unarchive ',
      class: 'btn-unarchive',
      iconclass: 'btn-icon-unarchive',
      smallclass: 'btn-small-unarchive',
    },
    approved: {
      icon: 'check_circle_outline',
      text: 'Approve',
      class: 'btn-approved',
      iconclass: 'btn-icon-approved',
      smallclass: 'btn-small-approved',
    },
    reject: {
      icon: 'report_off',
      text: 'Reject',
      class: 'btn-reject',
      iconclass: 'btn-icon-reject',
      smallclass: 'btn-small-reject',
    },
    print: {
      icon: 'print',
      text: 'Print',
      class: 'btn-print',
      iconclass: 'btn-icon-print',
      smallclass: 'btn-small-print',
    },
    download: {
      icon: 'cloud_download',
      text: 'Download',
      class: 'btn-download',
      iconclass: 'btn-icon-download',
      smallclass: 'btn-small-download',
    },
  }

  active: any = null;

  init() {
    if (this.custom) {
      this.active = {
        text: this.custom,
        icon: this.icon ? this.icon : this.details[this.text].icon,
        class: this.details[this.text].class || 'btn-default',
        iconclass: this.details[this.text].iconclass || 'btn-icon-default',
        smallclass: this.details[this.text].smallclass || 'btn-small-default',
      }
    } else {
      this.active = this.details[this.text] ? this.details[this.text] : this.details['submit'];
      this.active['icon'] = this.icon ? this.icon : this.active.icon;
    }
  }

  handleClick(event: any) {
    if (!this.disabled) {
      this.onclick.emit(event);
    }
  }
}
