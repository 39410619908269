import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import * as _ from 'lodash';
import { makeParams } from 'app/modules-core/utility/helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var QuickPanelService = /** @class */ (function () {
    function QuickPanelService(http) {
        this.http = http;
        this.urlBase = 'notifications';
        this.httpOptions = {
            params: new HttpParams()
        };
    }
    QuickPanelService.prototype.getAllNotification = function (opts) {
        if (opts === void 0) { opts = {}; }
        return this.http
            .get(this.urlBase, {
            params: makeParams(_.merge({
                httpParams: this.httpOptions.params
            }, { queryParams: opts }))
        })
            .pipe(map(function (resp) { return resp.data; }));
    };
    QuickPanelService.prototype.updateNotification = function (data) {
        return this.http.patch("" + this.urlBase, data)
            .pipe(map(function (resp) { return resp.data; }));
    };
    QuickPanelService.ngInjectableDef = i0.defineInjectable({ factory: function QuickPanelService_Factory() { return new QuickPanelService(i0.inject(i1.HttpClient)); }, token: QuickPanelService, providedIn: "root" });
    return QuickPanelService;
}());
export { QuickPanelService };
