import { Component } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  /**
   * Constructor
   */
  showTooltip = false;
  constructor(
    private router: Router
  ) {

  };
  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === "/planning-micro-alt") {
          this.showTooltip = true;
        }
        else
          this.showTooltip = false;
      }
    });



  }
}
