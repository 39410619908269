import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/internal/operators/map";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MigrateService = /** @class */ (function () {
    function MigrateService(http) {
        this.http = http;
        this.urlBase = "migrations";
        this.httpOptions = {
            params: new HttpParams(),
        };
    }
    MigrateService.prototype.run = function () {
        return this.http
            .get(this.urlBase + "/run")
            .pipe(map(function (resp) { return resp.data; }));
    };
    MigrateService.ngInjectableDef = i0.defineInjectable({ factory: function MigrateService_Factory() { return new MigrateService(i0.inject(i1.HttpClient)); }, token: MigrateService, providedIn: "root" });
    return MigrateService;
}());
export { MigrateService };
