/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar-filter-container.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sidebar-filter-container.component";
import * as i3 from "./sidebar-filter-container.service";
var styles_SidebarFilterContainerComponent = [i0.styles];
var RenderType_SidebarFilterContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarFilterContainerComponent, data: {} });
export { RenderType_SidebarFilterContainerComponent as RenderType_SidebarFilterContainerComponent };
export function View_SidebarFilterContainerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { content: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "theme-options-panel"], ["fusePerfectScrollbar", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, [[1, 3], ["content", 1]], null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_SidebarFilterContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sidebar-filter-container", [], null, null, null, View_SidebarFilterContainerComponent_0, RenderType_SidebarFilterContainerComponent)), i1.ɵdid(1, 245760, null, 0, i2.SidebarFilterContainerComponent, [i1.ComponentFactoryResolver, i3.SidebarFilterContainerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarFilterContainerComponentNgFactory = i1.ɵccf("sidebar-filter-container", i2.SidebarFilterContainerComponent, View_SidebarFilterContainerComponent_Host_0, {}, {}, []);
export { SidebarFilterContainerComponentNgFactory as SidebarFilterContainerComponentNgFactory };
