import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/internal/operators/map";
import { makeParams } from "app/modules-core/utility/helpers";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class MigrateService {
  urlBase = "migrations";
  httpOptions = {
    params: new HttpParams(),
  };
  constructor(private http: HttpClient) { }


  run() {

    return this.http
      .get(`${this.urlBase}/run`)
      .pipe(map((resp: any) => resp.data));
  }
}
