import { NgModule } from "@angular/core";
import { DateTimeModule } from "./date-time-picker/date-time.module";
import { NiceDatePipe } from "./nice-date.pipe";
import { AutocompleteBase } from "./autocomplete-base";
import { PageTitleService } from "./page-title.service";
import { RemoveCommaPipe } from "./removeComma.pipe";


@NgModule({
  declarations: [
    NiceDatePipe,
    AutocompleteBase,
    RemoveCommaPipe
  ],
  imports: [
    DateTimeModule,
  ],
  exports: [
    DateTimeModule,
    NiceDatePipe,
    RemoveCommaPipe
  ],
  providers: [
    PageTitleService,
  ]
})
export class UtilityModule {
  constructor(titleService: PageTitleService) {
    titleService.init();
  }
}