import { Component, OnInit, Inject, Directive, HostListener, Input } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Content } from '@angular/compiler/src/render3/r3_ast';

@Component({
  selector: 'modal-custom',
  template: `
  <style>
  </style>

  <h2 mat-dialog-title *ngIf="data?.title">{{ data.title }}</h2>

  <mat-dialog-content>
    <p *ngFor="let error of data.errors">
    {{ error }}
    </p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button
      mat-dialog-close>Close
    </button>
  </mat-dialog-actions>
  `,
})
export class ModalCustomComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void { }
}


