import { Subject } from 'rxjs/Subject';
import * as i0 from "@angular/core";
var LoginSubjectService = /** @class */ (function () {
    function LoginSubjectService() {
        this.loggedIn = new Subject();
        this.loggedIn$ = this.loggedIn.asObservable();
    }
    LoginSubjectService.ngInjectableDef = i0.defineInjectable({ factory: function LoginSubjectService_Factory() { return new LoginSubjectService(); }, token: LoginSubjectService, providedIn: "root" });
    return LoginSubjectService;
}());
export { LoginSubjectService };
