import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import * as _ from 'lodash';
import { makeParams } from 'app/modules-core/utility/helpers';

@Injectable({
  providedIn: 'root'
})
export class QuickPanelService {

  urlBase = 'notifications';

  constructor(
    private http: HttpClient,
  ) { }

  httpOptions = {
    params: new HttpParams()
  };

  getAllNotification(opts = {}) {
    return this.http
      .get(this.urlBase, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params
            },
            { queryParams: opts }
          )
        )
      })
      .pipe(map((resp: any) => resp.data));
  }

  updateNotification(data) {
    return this.http.patch(`${this.urlBase}`, data)
      .pipe(map((resp: any) => resp.data));
  }

}
