import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  defaultConfig = {
    duration: 3000,
    panelClass: null,
  }
  constructor(private snackBar: MatSnackBar) { }

  open(message: string, action: string, actionType: string = null, disabledAutoHide: boolean = false) {
    if (actionType == 'success') {
      this.defaultConfig.panelClass = 'snackbar-success'
    } else if (actionType == 'warning') {
      this.defaultConfig.panelClass = 'snackbar-warning'
    } else if (actionType == 'error') {
      this.defaultConfig.panelClass = 'snackbar-error'
    } else if (actionType == 'backend-error') {
      this.defaultConfig.panelClass = 'snackbar-backend-error'
    } else {
      this.defaultConfig.panelClass = null
    }
    disabledAutoHide ? this.defaultConfig.duration = null : null;
    this.snackBar.open(message, action, this.defaultConfig);
  }

}