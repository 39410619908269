import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { SERVER_API_URL } from 'app/app.constants';
import { LOCAL_STORAGE_TOKEN } from 'app/storage.constants';

export class AuthInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request || !request.url || (/^http/.test(request.url) && !(SERVER_API_URL && request.url.startsWith(SERVER_API_URL)))) {
      return next.handle(request);
    }

    const token = window.localStorage[LOCAL_STORAGE_TOKEN];
    if (!!token) {
      request = request.clone({
        setHeaders: {
          // Authorization: 'Bearer ' + token
          accessToken: token
        }
      });
    }
    return next.handle(request);
  }
}
