var ɵ0 = {
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
};
var GeneralModule = /** @class */ (function () {
    function GeneralModule() {
    }
    return GeneralModule;
}());
export { GeneralModule };
export { ɵ0 };
