/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "@angular/material/dialog";
import * as i13 from "./btn.component";
var styles_BtnComponent = [i0.styles];
var RenderType_BtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BtnComponent, data: {} });
export { RenderType_BtnComponent as RenderType_BtnComponent };
function View_BtnComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "s-16 pt-4 mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.active.icon; _ck(_v, 2, 0, currVal_1); }); }
function View_BtnComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.faicon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BtnComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["fxFlex", ""], ["fxFlexAlign", "center"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i5.FlexDirective, [i6.MediaMonitor, i1.ElementRef, [3, i5.LayoutDirective], i6.StyleUtils, i6.LAYOUT_CONFIG], { flex: [0, "flex"] }, null), i1.ɵdid(2, 737280, null, 0, i5.FlexAlignDirective, [i6.MediaMonitor, i1.ElementRef, i6.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BtnComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BtnComponent_4)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.faicon; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.faicon; _ck(_v, 6, 0, currVal_3); }, null); }
function View_BtnComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "button", [["class", "mx-4 px-8 py-4 btn-regular"], ["mat-stroked-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BtnComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "span", [["fxFlex", ""], ["fxFlexAlign", "center"]], null, null, null, null, null)), i1.ɵdid(7, 737280, null, 0, i5.FlexDirective, [i6.MediaMonitor, i1.ElementRef, [3, i5.LayoutDirective], i6.StyleUtils, i6.LAYOUT_CONFIG], { flex: [0, "flex"] }, null), i1.ɵdid(8, 737280, null, 0, i5.FlexAlignDirective, [i6.MediaMonitor, i1.ElementRef, i6.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵted(9, null, ["\u00A0", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "mx-4 px-8 py-4 btn-regular"; var currVal_3 = _co.active.class; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.disabled; _ck(_v, 3, 0, currVal_4); var currVal_5 = !_co.iconHide; _ck(_v, 5, 0, currVal_5); var currVal_6 = ""; _ck(_v, 7, 0, currVal_6); var currVal_7 = "center"; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_8 = _co.active.text; _ck(_v, 9, 0, currVal_8); }); }
function View_BtnComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "s-16 pt-4 mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.active.icon; _ck(_v, 2, 0, currVal_1); }); }
function View_BtnComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.faicon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BtnComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["fxFlex", ""], ["fxFlexAlign", "center"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i5.FlexDirective, [i6.MediaMonitor, i1.ElementRef, [3, i5.LayoutDirective], i6.StyleUtils, i6.LAYOUT_CONFIG], { flex: [0, "flex"] }, null), i1.ɵdid(2, 737280, null, 0, i5.FlexAlignDirective, [i6.MediaMonitor, i1.ElementRef, i6.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BtnComponent_7)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BtnComponent_8)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.faicon; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.faicon; _ck(_v, 6, 0, currVal_3); }, null); }
function View_BtnComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "button", [["class", "mx-4 px-8 py-4 btn-regular"], ["mat-dialog-close", ""], ["mat-stroked-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).dialogRef.close(i1.ɵnov(_v, 4).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.handleClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), i1.ɵdid(4, 606208, null, 0, i12.MatDialogClose, [[2, i12.MatDialogRef], i1.ElementRef, i12.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BtnComponent_6)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 3, "span", [["fxFlex", ""], ["fxFlexAlign", "center"]], null, null, null, null, null)), i1.ɵdid(8, 737280, null, 0, i5.FlexDirective, [i6.MediaMonitor, i1.ElementRef, [3, i5.LayoutDirective], i6.StyleUtils, i6.LAYOUT_CONFIG], { flex: [0, "flex"] }, null), i1.ɵdid(9, 737280, null, 0, i5.FlexAlignDirective, [i6.MediaMonitor, i1.ElementRef, i6.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵted(10, null, ["\u00A0", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "mx-4 px-8 py-4 btn-regular"; var currVal_4 = _co.active.class; _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_5 = _co.disabled; _ck(_v, 3, 0, currVal_5); var currVal_6 = ""; _ck(_v, 4, 0, currVal_6); var currVal_7 = !_co.iconHide; _ck(_v, 6, 0, currVal_7); var currVal_8 = ""; _ck(_v, 8, 0, currVal_8); var currVal_9 = "center"; _ck(_v, 9, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); var currVal_2 = i1.ɵnov(_v, 4).ariaLabel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_10 = _co.active.text; _ck(_v, 10, 0, currVal_10); }); }
export function View_BtnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BtnComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BtnComponent_5)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.modalClose; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.modalClose; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "btn", [], null, null, null, View_BtnComponent_0, RenderType_BtnComponent)), i1.ɵdid(1, 114688, null, 0, i13.BtnComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BtnComponentNgFactory = i1.ɵccf("btn", i13.BtnComponent, View_BtnComponent_Host_0, { text: "text", disabled: "disabled", custom: "custom", iconHide: "iconHide", icon: "icon", faicon: "faicon", modalClose: "modalClose" }, { onclick: "onclick" }, []);
export { BtnComponentNgFactory as BtnComponentNgFactory };
