import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule, MatBadgeModule, MatDialogModule } from '@angular/material';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';

import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { ButtonModule } from 'app/modules-core/utility/button/button.module';
import { DiaryModule } from 'app/modules-core/diary/diary.module';

@NgModule({
  declarations: [
    ToolbarComponent
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatBadgeModule,
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    DiaryModule,
    NgProgressModule.forRoot(),
    NgProgressHttpModule,
    ButtonModule,
    MatDialogModule,
  ],
  exports: [
    ToolbarComponent
  ]
})
export class ToolbarModule {
}
