import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { UserService } from 'app/modules-core/common/user/user.service';
import { LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_USER } from 'app/storage.constants';

@Injectable({ providedIn: 'root' })
export class AuthService {
  currentUserString = '';
  currentUser = <any>{};
  currentUserPermissions = {};

  constructor(
    private router: Router,
    private _userService: UserService,
  ) {
    this.getCurrentUser();
  }

  getCurrentUser() {
    if (this.currentUserString = window.localStorage[LOCAL_STORAGE_USER]) {
      this.currentUser = JSON.parse(this.currentUserString);
      this.currentUserPermissions = _.get(this.currentUser, 'roles.0.permissions');
    }

    return this.currentUser;
  }

  //todo
  login() {

  }

  hasPermission(permissionName) {
    if (this.currentUserPermissions) {
      return !!_.find(this.currentUserPermissions, perms => _.get(perms, 'name') === permissionName);
    }
    return false;
  }

  logout() {
    this._userService.logout().subscribe(data => {
      console.log('Logout Backend!');
    }).add(() => {
      console.log('Logout!');
      window.localStorage.removeItem(LOCAL_STORAGE_USER);
      window.localStorage.removeItem(LOCAL_STORAGE_TOKEN);
      this.router.navigate(['login']);
    });;


  }


}
