
  <style>
  </style>

  <h2 mat-dialog-title *ngIf="data?.title">{{ data.title }}</h2>

  <mat-dialog-content>
    <p *ngFor="let error of data.errors">
    {{ error }}
    </p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button
      mat-dialog-close>Close
    </button>
  </mat-dialog-actions>
  