import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatFormFieldModule, MatInputModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { ForgotPasswordComponent } from 'app/authentication/forgot-password/forgot-password.component';
import { PasswordResetFormComponent } from './password-reset-form/password-reset-form.component';
import { ButtonModule } from 'app/modules-core/utility/button/button.module';

const routes = [
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },

  {
    path: 'reset-password',
    component: PasswordResetFormComponent
  }
];

@NgModule({
  declarations: [
    ForgotPasswordComponent,
    PasswordResetFormComponent
  ],
  imports: [
    RouterModule.forChild(routes),

    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ButtonModule,
    FuseSharedModule
  ]
})
export class ForgotPasswordModule {
}
