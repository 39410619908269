
    <ng-container *ngIf="!modalClose">
      <button [disabled]="disabled" mat-stroked-button class="mx-4 px-8 py-4 btn-regular" [ngClass]="active.class"
        (click)="handleClick($event)">
        <span fxFlex fxFlexAlign="center" *ngIf="!iconHide">
          <mat-icon *ngIf="!faicon" class="s-16 pt-4" >{{active.icon}}</mat-icon> 
          <span *ngIf="faicon" [ngClass]="faicon"></span>
        </span>
        <span fxFlex fxFlexAlign="center">&nbsp;{{active.text}}</span>
      </button>
    </ng-container>
    <ng-container  *ngIf="modalClose">
      <button [disabled]="disabled" mat-stroked-button  class="mx-4 px-8 py-4 btn-regular" [ngClass]="active.class"
        mat-dialog-close
        (click)="handleClick($event)">
        <span fxFlex fxFlexAlign="center" *ngIf="!iconHide">
          <mat-icon *ngIf="!faicon" class="s-16 pt-4" >{{active.icon}}</mat-icon> 
          <span *ngIf="faicon" [ngClass]="faicon"></span>
        </span>
        <span fxFlex fxFlexAlign="center">&nbsp;{{active.text}}</span>
      </button>
    </ng-container>
  