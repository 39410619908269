import { NgModule } from '@angular/core';
import { AuthModule } from 'app/authentication/auth.module';
import { LayoutModule } from 'app/layout/layout.module';
import { FormsModule } from '@angular/forms';
import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';
import { VerticalLayout2Module } from 'app/layout/vertical/layout-2/layout-2.module';
import { VerticalLayout3Module } from 'app/layout/vertical/layout-3/layout-3.module';
import { HorizontalLayout1Module } from 'app/layout/horizontal/layout-1/layout-1.module';
import { ButtonModule } from '../utility/button/button.module';

@NgModule({
  declarations: [
  ],
  imports: [
    LayoutModule,
    AuthModule,
    ButtonModule,
    FormsModule,
    VerticalLayout1Module,
    VerticalLayout2Module,
    VerticalLayout3Module,
    HorizontalLayout1Module,
  ],
  exports: [
    ButtonModule,
    VerticalLayout1Module,
    VerticalLayout2Module,
    VerticalLayout3Module,
    HorizontalLayout1Module,
    LayoutModule
  ],
})
export class CommonSharedModule { }
