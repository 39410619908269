import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { DiaryService } from '../../diary.service';
import { AuthService } from 'app/authentication/auth.service';
import { QuickPanelSubjectService } from 'app/layout/components/quick-panel/quick-panel-subject.service';
import { SnackbarService } from 'app/modules-core/utility/snackbar.service';

@Component({
  selector: 'app-diary-modal',
  templateUrl: './diary-modal.component.html',
  animations: fuseAnimations,
})
export class DiaryModalComponent {

  constructor(
    public dialogRef: MatDialogRef<DiaryModalComponent>,
    private _diaryService: DiaryService,
    public dialog: MatDialog,
    private snackBar: SnackbarService,
    private _authService: AuthService,
  ) { }

  diary = <any>[];
  note = null;

  ngOnInit() {
    this.getDiary();
  }

  getDiary() {
    this._diaryService.getAll({ 'userId': this._authService.getCurrentUser().id }).subscribe(data => {
      if (data) {
        this.note = data[0].note;
      }
    })
  }



  addDiary() {
    if (!this.note) {
      this.snackBar.open(`Diary note must be provided!`, 'Close');
      return;
    }
    let payload = { note: this.note }
    //this.diary.user = { id: this._authService.getCurrentUser().id }
    this._diaryService.create(payload).subscribe(res => {
      this.getDiary();
      this.snackBar.open(`Diary added!`, 'Close');
    })
  }
}


