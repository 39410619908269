<div id="forgot-password"
  fxLayout="column">

  <div id="forgot-password-form-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center">

    <div id="forgot-password-form"
      [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

      <div class="logo">
        <img src="assets/images/logos/fuse.svg">
      </div>

      <div class="title">RECOVER YOUR PASSWORD</div>

      <form name="forgotPasswordForm"
        [formGroup]="forgotPasswordForm"
        novalidate>

        <mat-form-field>
          <input matInput
            [(ngModel)]='data.email'
            placeholder="Email"
            formControlName="email">
          <mat-error *ngIf="forgotPasswordFormErrors.email.required">
            Email is required
          </mat-error>
          <mat-error
            *ngIf="!forgotPasswordFormErrors.email.required && forgotPasswordFormErrors.email.email">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <button mat-raised-button
          class="submit-button"
          color="accent"
          aria-label="SEND RESET LINK"
          [disabled]="forgotPasswordForm.invalid"
          (click)="onEmailSend()">
          SEND RESET LINK
        </button>
      </form>

      <div class="login"
        fxLayout="row"
        fxLayoutAlign="center center">
        <a class="link"
          [routerLink]="'/login'">Go back to login</a>
      </div>

    </div>

  </div>

</div>