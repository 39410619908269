import { NgModule } from "@angular/core";
import { GeneralAutocomplete } from "./components/general-autocomplete.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FuseSharedModule } from "@fuse/shared.module";
import { MaterialModule } from "../utility/material.module";
import { ModalShowOnClickDirective } from "./modal.directive";
import { SnackbarCustomComponent, SnackBarDismissDirective } from "./snackbar-custom.component";
import { ModalCustomComponent } from "./modal-custom.component";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material";
import { SorterIconComponent } from "./sorter-icon";
import { NumberOnlyDirective } from "./number-only.directive";

@NgModule({
  declarations: [
    GeneralAutocomplete,
    SorterIconComponent,
    ModalShowOnClickDirective,
    SnackbarCustomComponent,
    SnackBarDismissDirective,
    ModalCustomComponent,
    NumberOnlyDirective,
  ],
  entryComponents: [
    SnackbarCustomComponent,
    ModalCustomComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule,
    MaterialModule,
    NgSelectModule,
  ],
  exports: [
    GeneralAutocomplete,
    SorterIconComponent,
    ModalShowOnClickDirective,
    NumberOnlyDirective,
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      }
    }
  ]
})
export class GeneralModule {

}