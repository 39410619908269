import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs/Observable';
import * as _ from "lodash";
import { makeParams } from 'app/modules-core/utility/helpers';

@Injectable({
  providedIn: 'root'
})
export class DiaryService {

  urlBase = 'diaries';

  httpOptions = {
    params: new HttpParams()
  };

  constructor(
    private http: HttpClient,
  ) { }

  getAll(opts = {}) {
    return this.http
      .get(`${this.urlBase}`, {
        params: makeParams(
          _.merge(
            {
              httpParams: this.httpOptions.params
            },
            { queryParams: opts }
          )
        )
      })
      .pipe(map((resp: any) => resp.data));
  }

  getById(id) {
    return this.http.get(`${this.urlBase}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  delete(id) {
    return this.http.delete(`${this.urlBase}/${id}`)
      .pipe(map((resp: any) => resp.data));
  }

  create(data) {
    return this.http.post(
      `${this.urlBase}`,
      data
    ).pipe(map((resp: any) => resp.data));
  }

  update(id, data) {
    return this.http.put(
      `${this.urlBase}/${id}`,
      data
    ).pipe(map((resp: any) => resp.data));
  }

}
